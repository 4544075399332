/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { inject } from '@angular/core';
import { UserService } from 'app/core/user/user.service';

const getUser = (): any => {
    const token = localStorage.getItem('accessToken') ?? '';
    // Split the token into parts
    const parts = token.split('.');
    const header = parts[0];
    const payload = parts[1];
    const signature = parts[2];


    const userJson = JSON.parse(atob(payload));
    return userJson;
}


var isAdmin: () => boolean = () => {
    const user = getUser();
    const isUserAdmin = user.admin as boolean || false;
    return isUserAdmin;
}

var isFullMember: () => boolean = () => {
    const user = getUser();    
    const isUserAdmin = user.isFullMember as boolean || false;
    return isUserAdmin;
}

var canSeeWorkingGroups: () => boolean = () => {
    const user = getUser();
    if (user.isFullMember) return true;

    if (user.groups.length > 0) return true;

    return false;
};

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Home',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    },
    {
        id   : 'new-members',
        title: 'New Members',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/new-members'
    },
    {
        id   : 'solicitations',
        title: 'Solicitations',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/solicitations',
        hidden : (item) => {
            // const userService = inject(UserService);
            // console.log(userService);
            return !isFullMember();
        },
    },
    {
        id   : 'working-groups-exp',
        title: 'Working Groups',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/working-groups',
        hidden : (item) => {
            // const userService = inject(UserService);
            // console.log(userService);
            return !canSeeWorkingGroups();
        },
    },
    // {
    //     id   : 'working-groups',
    //     title: 'Document Test',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example/working-groups-tst'
    // },
    {
        id   : 'app-resources',
        title: 'Resources',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/resources'
    },
    {
        id   : 'example',
        title: 'NSC News and Events',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : 'https://www.nationalspectrumconsortium.org/news',
        externalLink : true,
        target : '_blank'
    },
    {
        id   : 'app-social-media',
        title: 'Follow Us on Social Media',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/social-media'
    },
    {
        id   : 'document-list',
        title: 'Document Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/document-list',
        hidden : (item) => {
            // const userService = inject(UserService);
            // console.log(userService);
            return !isAdmin();
        },
    },
    //TODO: rev
    {
        id   : 'news-list',
        title: 'News Article Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/news-list',
        hidden : (item) => {
            // const userService = inject(UserService);
            // console.log(userService);
            return !isAdmin();
        },
    },
    {
        id   : 'award-list',
        title: 'Project Award Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/award-list',
        hidden : (item) => {
            // const userService = inject(UserService);
            // console.log(userService);
            return !isAdmin();
        },
    },
    {
        id   : 'submission-admin',
        title: 'Submission Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/submission-request-list',
        hidden : (item) => {
            // const userService = inject(UserService);
            // console.log(userService);
            return !isAdmin();
        },
    },
    {
        id   : 'content-admin',
        title: 'Content Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example/content-list',
        hidden : (item) => {
            // const userService = inject(UserService);
            // console.log(userService);
            return !isAdmin();
        },
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/docum'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
